import { Container, Row, Col, Tab, Nav, TabContainer } from "react-bootstrap";
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import TrackVisibility from 'react-on-screen';
import ProjectCard from "./ProjectCard";
import 'animate.css';

export const Projects = () => {

  const projects = [
    {
      title: "Ecommerce Website",
      skillUsed: "Fullstack Development",
      description:"Group project where worked on",
      tech_used:"UI/UX, Recommendation and searching algorithm, Project management",
      imgUrl: projImg1,
      url: "https://7thsemesterproject.vercel.app/",  
    },
    {
      title: "Portfolio Website",
      skillUsed: "Front End Development",
      description:"I built this solo using:",
      tech_used:"ReactJS, CSS and React Bootstrap",
      imgUrl: projImg2,
      url: "https://my-porfolio-six-gamma.vercel.app/",  
    },
    {
      title: "School Alumni Website",
      skillUsed: "Group",
      description:"I built this solo using:",
      tech_used:"MERN, MongoDB Atlas, Cloudinary",
      imgUrl: projImg3,
      url: "https://birbhaktisamaj.org.np",  
    },
  ];

  return (
    <section className="project" id="project">
        <Container>
            <Row>
                <Col>
                <TrackVisibility>
            {({isVisible})=>
              <div className={isVisible?"animated__animated animate__bounceIn":""}>       
                <h2>Projects</h2>
                <p>Here are some projects I've worked on</p>
                </div>}
            </TrackVisibility>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                <Nav variant="pills" defaultActiveKey="/home">
                    <Nav.Item>
                        <Nav.Link eventKey="first">Page One</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="second">Page Two</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="third">
                        Page Three
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
                <br></br>
                <Tab.Content>
                    <Tab.Pane eventKey="first">
                        <Row>
                            {
                                projects.map((project,i)=>{
                                    return(
                                        <ProjectCard
                                        key={i}
                                        {...project}
                                        />
                                )
                                })
                            }
                        </Row>
                    </Tab.Pane>
                </Tab.Content>
                </Tab.Container>
                </Col>
            </Row>
        </Container>
        <img src={colorSharp2} className="background-image-right"></img>
      
    </section>
  )
}

export default Projects

