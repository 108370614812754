import './App.css';
import Banner from './components/Banner.js';
import NavBar from './components/NavBar.js';
import 'bootstrap/dist/css/bootstrap.min.css'
import Skills from './components/Skills.js';
import Experience from './components/Experience.js';
import Projects from './components/Projects.js';
import Footer from './components/Footer.js';
import Contact  from './components/Contact.js';
import { useState } from 'react';



function App() {
  const [result, setResult] = useState(0);

  // Function to update the result
  const handleVisibility = (value) => {
    setResult(value);
  };
  return (
    <div className="App">
      <NavBar/>
      <Banner/>
      <Experience result={result}/>
      <Skills onVisible={handleVisibility}/>
      <Projects/>
      <Contact/>
      <Footer/>
    </div>
  );
}

export default App;
