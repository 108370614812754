import React from 'react';
import { Col } from "react-bootstrap";

const ProjectCard = ({ imgUrl, title, description, tech_used, url }) => {
  const handleRedirect = (url) => {
    window.open(url, '_blank'); // Open the project in a new tab
  };

  return (
    <Col size={12} sm={6} md={4}>
      <div className="proj-imgbx" onClick={() => handleRedirect(url)}>
        <img src={imgUrl} alt={title} />
        <div className="proj-txtx">
          <h4>{title}</h4>
          <span>{description}</span>
          <br />
          <span>{tech_used}</span>
        </div>
      </div>
    </Col>
  );
};

export default ProjectCard;
