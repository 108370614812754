import meter1 from "../assets/img/meter1.svg";
import meter2 from "../assets/img/meter2.svg";
import meter3 from "../assets/img/meter3.svg";
import meter4 from "../assets/img/meter4.svg";
import meter5 from "../assets/img/meter5.svg";
import meter6 from "../assets/img/meter6.svg";
import meter7 from "../assets/img/meter7.svg";
import meter8 from "../assets/img/meter8.svg";
import meter9 from "../assets/img/meter9.svg";
import meter10 from "../assets/img/meter10.svg";
import meter11 from "../assets/img/meter11.svg";
import colorSharp from "../assets/img/color-sharp.png";
import { useEffect, useRef, useState } from "react";

const Skills = ({ onVisible }) => {
  const [isVisible, setIsVisible] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        setIsVisible(entry.isIntersecting); 
      
      },
      {
        threshold: 0.5, // Element is considered visible if 50% of it is in view
      }
    );

    if (elementRef.current) {
      observer.observe(elementRef.current); // Start observing the element
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current); // Clean up observer
      }
    };
  }, []);

  const skills = [
    { image: meter1, name: "MERN Stack", category: "Core Web Development" },
    { image: meter3, name: "HTML", category: "Core Web Development" },
    { image: meter4, name: "CSS", category: "Core Web Development" },
    { image: meter5, name: "JavaScript", category: "Core Web Development" },
    { image: meter2, name: "React Bootstrap", category: "Supplemental" },
    { image: meter8, name: "Redux", category: "Supplemental" },
    { image: meter9, name: "Chakra UI", category: "Supplemental" },
    { image: meter11, name: "Material UI", category: "Supplemental" },
    { image: meter6, name: "Cloudinary", category: "Cloud" },
    { image: meter10, name: "MongoDB atlas", category: "Cloud" },
    { image: meter7, name: "Vercel", category: "Hosting" },
  ];

  const groupedSkills = skills.reduce((acc, skill) => {
    if (!acc[skill.category]) acc[skill.category] = [];
    acc[skill.category].push(skill);
    return acc;
  }, {});

  // This console.log will run when isVisible is true
  useEffect(() => {
    if (isVisible) {
      onVisible("abc")
    }
    else{
      onVisible("0")
    }
  }, [isVisible]); // Only runs when isVisible changes

  return (
    <section className="skill" id="skills" ref={elementRef}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="skill-bx wow zoomIn">
              <h2>Skills</h2>
              <p>
                Here are the major technologies I have experience with
                <br />
              </p>
              <div className="skills-grid">
                {Object.entries(groupedSkills).map(([category, skills]) => (
                  <div key={category} className="skills-category-box">
                    <h3 className="skills-category-title">{category}</h3>
                    <div className="skills-list">
                      {skills.map((skill, i) => (
                        <div key={i} className="skill-item">
                          <img src={skill.image} alt={skill.name} />
                          <p>{skill.name}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <img className="background-image-left" src={colorSharp} alt="Background" />
    </section>
  );
};

export default Skills;
