
import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../assets/img/logo.svg'
import navIcon1 from '../assets/img/nav-icon1.svg'
import navIcon2 from '../assets/img/nav-icon2.svg'
import navIcon3 from '../assets/img/nav-icon3.svg'


const NavBar = () => {
  const [activeLink, setActiveLink]= useState('home')
  const[scrolled, setScrolled]= useState(false)
  useEffect(()=>{
    const onScroll = ()=>{
      if(window.scrollY>50){
        setScrolled(true)
      }
      else{
        setScrolled(false)
      }
    }
    window.addEventListener("scroll",onScroll)
    return ()=> window.removeEventListener("scroll", onScroll)
  },[])
  const onUpdateActiveLink = (value)=>{
    setActiveLink(value)
  }
    return (
        <Navbar expand="md" className={scrolled?"scrolled":"not"}>
          <Container>
          <Navbar.Brand href="/">
            <img src={logo} alt="Logo" />
          </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" >
              <span className='navbar-toggler-icon'></span>
             </Navbar.Toggle>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link href="#home" className={activeLink==='home'?'active navbar-link':'navbar-link'} onClick={()=>onUpdateActiveLink('home')}>Home</Nav.Link>
                <Nav.Link href="#skills" className={activeLink==='skills'?'active navbar-link':'navbar-link'} onClick={()=>onUpdateActiveLink('skills')}>Skills</Nav.Link>
                <Nav.Link href="#project" className={activeLink==='project'?'active navbar-link':'navbar-link'} onClick={()=>onUpdateActiveLink('project')}>Projects</Nav.Link>
                <Nav.Link href="#connect" className={activeLink==='contact'?'active navbar-link':'navbar-link'} onClick={()=>onUpdateActiveLink('contact')}>Contact</Nav.Link>
              </Nav>
              <span className="navbar-text">
                <div className = "social-icon">
                  <a href="https://www.linkedin.com/in/sushant-pant-9876a9330/" target="_blank" 
        rel="noopener noreferrer"><img src={navIcon1} alt=""/></a>
                  <a href="https://github.com/SushantPant" target="_blank" 
        rel="noopener noreferrer"><img src={navIcon2} alt=""/></a>
                  <a href="https://gitlab.com/sushantpant34" target="_blank" 
        rel="noopener noreferrer"><img src={navIcon3} alt=""/></a>
                </div>
                <button 
  className="vvd" 
  onClick={() => window.open(`${process.env.PUBLIC_URL}/cv.pdf`, '_blank')}
>
  <span>Download CV</span>
</button>

             </span>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      );
    }

export default NavBar
