import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import contactImg from "../assets/img/contact-img.svg";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

const Contact = () => {
  const buttonStyle = {
    backgroundColor: '#121212', 
    color: 'white',             
    padding: '10px 20px',     
    fontSize: '16px',          
    border: 'none',             
    borderRadius: '8px',       
    cursor: 'pointer',         
    transition: 'background-color 0.3s', 
  };
  const email = 'sushantpant34@gmail.com';

  const buttonHoverStyle = {
    ...buttonStyle,
    backgroundColor: '#222222',
  };
  const handleClick = ()=>{
    const mailToLink = `mailto:${email}`;
    window.location.href = mailToLink;
  }
  return (
    <section className="contact" id="connect">
      <Container>
        <Row className="align-items-center">
          <Col size={12} md={6}>
            <TrackVisibility partialVisibility={true} threshold={0.9}>
              {({ isVisible  }) =>
                <img className={isVisible ? "animate__animated animate__zoomIn"  : "animate__animated animate__slideOutDown"} src={contactImg} alt="Contact Us"/>
              }
            </TrackVisibility>
          </Col>
          <Col size={12} md={6}>
          <TrackVisibility partialVisibility threshold={0.8}>
              {({ isVisible }) =>
          <div className={isVisible ? "animate__animated animate__zoomIn" : "animate__animated animate__slideOutDown"}>
                <h2>Contact</h2>
                <button
      style={buttonStyle}
      onMouseOver={(e) => (e.target.style.backgroundColor = buttonHoverStyle.backgroundColor)}
      onMouseOut={(e) => (e.target.style.backgroundColor = buttonStyle.backgroundColor)}
      onClick={handleClick}
    >
      Send Email
    </button>
          </div>}
          </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Contact