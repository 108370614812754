import React, { useEffect, useRef, useState } from "react";

const Experience = ({ result }) => {
  const timelineRef = useRef(null);
  const [whiteLineHeight, setWhiteLineHeight] = useState(0); 
  console.log(`outside ${result}`)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        const intersectionRatio = entry.intersectionRatio;
        if (intersectionRatio === 0) {
          setWhiteLineHeight(0);
        } else {
      
          const visibleHeight = Math.floor(intersectionRatio * 100); 
          const whiteHeight = result==0?Math.min(visibleHeight * 0.9, 100):90; 
          console.log(whiteHeight)
          setWhiteLineHeight(whiteHeight); 
          console.log(`inside ${result}`)
        }
      },
      { threshold: Array.from({ length: 11 }, (_, i) => i / 10) } 
    );

    if (timelineRef.current) {
      observer.observe(timelineRef.current);
    }

    return () => {
      if (timelineRef.current) {
        observer.unobserve(timelineRef.current);
      }
    };
  }, [result]);

  const experience = [
    { name: "St Lawrence College", post: "Bachelors in Computer Science And Technology", duration: "2020 - Ongoing" },
    { name: "DWIT", post: "MERN STACK Trainee", duration: "110 Hours" },
    { name: "Bir Bhakti Alumni Association", post: "Full stack developer contract", duration: "September 2024 - November 2024" },

  ];

  return (
    <section className="experience" id="experience" ref={timelineRef}>
      <div className="container">
        <h2>Experience</h2>
        <p>
          Here is my Academic and Professional Experience
        </p>
        <div className="timeline-wrapper">
          <div className="timeline">
            {/* Base vertical line */}
            <div className="timeline-base-line" style={{ backgroundColor: "white", height: `${whiteLineHeight}%` }}></div>

            {/* Map through experiences */}
            {experience.map((item, i) => (
              <div key={i} className="timeline-item">
                <div className={item.duration.includes("Ongoing") ? "timeline-ball-white" : "timeline-ball-green"}></div>
                <div className="timeline-content">
                  <p>{item.name}</p>
                  <span>{item.post}</span>
                  <span> ({item.duration})</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Experience;
